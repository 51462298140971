<template>
  <footer
    id="footer"
    class="txt-white"
  >
    <div class="container w1400">
      <div class="footer_logo_wrapper">
        <router-link to="/">
          <img src="../assets/imgs/index/logo_footer.svg" alt="">
        </router-link>
      </div>
      <div class="footer_content">
        <div class="left">
          <div class="mobile_title large suffix-redCube">{{lang==='TW' ? '聯絡我們': 'Contact'}}</div>
          <ul>
            <li v-for="(company, idx) in companies" :key="`company_${idx}`">
              <div class="company_branch small prefix-redCube">{{ company.branch }}</div>
              <div class="company_name small-x">{{ company.name }}</div>
            </li>
          </ul>
          <template
            v-for="(link, idx) in MenuList"
          >
            <router-link
              v-if="link.name === '聯絡我們'"
              :key="`footer_link_${idx}`"
              :to="{ name: link.to }"
            >
              <LinkMore
                :linkText="'CONTACT US'"
                :fillColor="'#fff'"
              />
            </router-link>
          </template>
        </div>
        <div class="right">
          <div class="mobile_title large suffix-redCube">{{lang==='TW' ? '產品介紹': 'Products'}}</div>
          <ul class="section_links pc">
            <li
              v-for="(link, idx) in MenuList"
              :key="`footer_link_${idx}`"
              v-show="link.name !== '聯絡我們'"
            >
              <router-link :to="routeData(link)">
                {{ lang === 'TW'? link.name : link.name_en }}</router-link>
            </li>
          </ul>
          <ul class="section_links mo">
            <li
              v-for="(series, idx) in serieses"
              :key="`footer_sub_link_${idx}`"
            >
              <router-link
                :to="routeData({ name: '產品介紹' }, series)"
                class="small"
              >
                {{ series.name }}
              </router-link>
            </li>
          </ul>
          <div class="go_top pc" @click="goTop">
            <NextArrow class="arrow_icon" fillColor="#fff" />
            <span class="p">TOP</span>
          </div>
          <div class="copy_right pc txt-center small-x">{{ copyRight }}</div>
        </div>
      </div>
      <div class="copy_right mo txt-center small-x">{{ copyRight }}</div>
    </div>
    <div class="go_top mo" @click="goTop">
      <NextArrow class="arrow_icon" fillColor="#fff" />
    </div>
  </footer>
</template>

<script>
import LinkMore from '@/components/LinkMore.vue';
import NextArrow from '@/components/icon/NextArrow.vue';
import {
  MenuList, serieses, companies, copyRight,
} from '@/lib/const';
import publicMixin from '@/mixins/publicMixin';
import goToProductMixin from '@/mixins/goToProductMixin';
import languageMixin from '@/mixins/languageMixin';

export default {
  name: 'Footer',
  mixins: [publicMixin, goToProductMixin, languageMixin],
  components: {
    LinkMore,
    NextArrow,
  },
  data() {
    return {
      MenuList,
      serieses,
      companies,
      copyRight,
    };
  },
  methods: {
    goTop() {
      this.scrollToTop(); // from publicMixin.js
    },
    routeData(parent, child) {
      let result = {
        name: parent.name === '產品介紹'
          ? 'Products' : parent.to,
      };
      if (parent.name === '產品介紹') {
        const lv1 = child
          ? child.name
          : this.menuListFromVuex?.[0]?.name;
        const lv2 = child
          ? this.menuListFromVuex
            ?.find((main) => main.name === child.name)?.list?.[0]?.name
          : this.menuListFromVuex?.[0]?.list?.[0]?.name;

        result = this.getProductRouteDate(result, lv1, lv2);
      }
      return result;
    },
  },
};
</script>
